import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
} from "@mui/material";
import BackLink from "src/components/back-link";
import TableHead from "src/components/table-head";
import APP_ROUTES from "src/routes/routePaths";
import BlankCard from "src/components/shared/BlankCard";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import { RequiredStar } from "src/components/required-star";
import { PreventWheelChange } from "src/utils/PreventWheelChange";
import axiosServices from "src/utils/axios";
import { notifyMessage } from "src/utils/toastNotify";
import { useSelector } from "react-redux";
import { IconInfoCircle } from "@tabler/icons-react";

interface clubChangeFeesConfiguration {
    "athlete_club_change_fees_configuration_id": number,
    "athlete_club_change_fees": number,
    "created_at": string,
    "updated_at": string,
    "is_active": number
}

function AthleteClubChangeFeesConfiguration() {
    const [planId, setPlanId] = useState<string | null>(null)
    const [clubChangeFeesPlan, setClubChangeFeesPlan] = useState<clubChangeFeesConfiguration | null>(null)
    const [fees, setFees] = useState("")
    const [openDialog, setOpenDialog] = useState(false)
    const [feeError, setFeeError] = useState("")
    const [feeOption, setFeeOption] = useState("no")

    const fetchClubChangeFee = async () => {
        try {
            const result = await axiosServices.get("/district/configuration/view-club-change-fees")
            if (result.data.success) {
                const data: clubChangeFeesConfiguration = result.data.data
                setClubChangeFeesPlan(data)
            } else {
                notifyMessage.error(result?.data?.message)
            }
        } catch (error: any) {
            notifyMessage.error(error?.response?.data?.message)
        }
    }

    useEffect(() => {
        fetchClubChangeFee()
    }, [])

    const handleOpenDialog = (configurationId: any) => {
        if (clubChangeFeesPlan && configurationId == clubChangeFeesPlan?.athlete_club_change_fees_configuration_id) {
            setOpenDialog(true)
            setPlanId(configurationId)
            setFees(String(clubChangeFeesPlan?.athlete_club_change_fees))
            if (clubChangeFeesPlan && clubChangeFeesPlan?.athlete_club_change_fees > 0) {
                setFeeOption("yes")
            }
        }
    }

    const handleFeeChange = (fee: string) => {
        if (!/^\d{0,10}$/.test(fee)) {
            setFeeError("Fee must be less than 1,000,000,000.");
        } else {
            setFees(fee)
        }
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
        setFeeError("")
        setPlanId(null)
        setFees("")
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
        if (!allowedKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handleUpdateFees = async () => {
        const parsedFee = Number(fees);
        if (!fees || isNaN(parsedFee) || parsedFee < 0) {
            setFeeError("Please enter a valid positive fee amount.");

            return;
        }
        if (planId != null) {
            try {
                const response = await axiosServices.put(`/district/configuration/update-club-change-fees/${planId}`,
                    { athlete_club_change_fees: feeOption == "yes" ? parsedFee : 0 })
                if (response?.data?.success) {
                    await fetchClubChangeFee()
                    notifyMessage.success(response?.data?.message)
                }
                handleCloseDialog()
            } catch (error: any) {
                notifyMessage.error(error?.response?.data?.message)
            }
        }
    }


    return (
        <>
            <BackLink title="Back to Configurations Tabs" route={`${APP_ROUTES.CLUB_ADMIN_CONFIGURAIONS}`} />
            <BlankCard>
                <TableHead title="Athlete Club Change Fees Configurations" />
                <Box sx={{ p: 3 }}>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Here you can configure the fees for athlete club change request.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            Please adjust the fees in accordance with your district's policy to facilitate athletes in submitting club change requests.
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} key={clubChangeFeesPlan?.athlete_club_change_fees}>
                            <Box
                                sx={{
                                    border: "1px solid #ccc",
                                    padding: 2,
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        Athlete Fees for  Club Change
                                        <Tooltip title="Enter 0 if no fee is required." arrow>
                                            <IconButton size="small" sx={{ mb: 1 }} color="secondary">
                                                <IconInfoCircle size="20" stroke={1.4} />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                    <Typography variant="body2">Set the club change fee for athletes opting for a club change.</Typography>
                                    <Typography variant="h6" sx={{ mt: 2 }}>
                                        {clubChangeFeesPlan && clubChangeFeesPlan?.athlete_club_change_fees == 0 ? `No fee is charged for club change.` : `Current Club Change Fee: ₹${clubChangeFeesPlan?.athlete_club_change_fees}`}
                                        {/* Current Fee: ₹{clubChangeFeesPlan?.athlete_club_change_fees} */}
                                    </Typography>
                                </Box>
                                <Button variant="contained" color="primary" onClick={() => handleOpenDialog(clubChangeFeesPlan?.athlete_club_change_fees_configuration_id)}>
                                    Update
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </BlankCard>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Athlete Club Change Fee</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Do you want to charge a fee for athlete club change?
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="profile-updation-fee"
                            value={feeOption}
                            onChange={(e) => setFeeOption(e.target.value)} // Handle the change to update the selected value
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    {feeOption == "yes" && (<>
                        <CustomFormLabel sx={{ mt: 0 }}>
                            New Fee
                            <RequiredStar />
                        </CustomFormLabel>
                        <CustomTextField
                            type="number"
                            fullWidth
                            value={fees}
                            onChange={(e: any) => handleFeeChange(e.target.value)}
                            onWheel={PreventWheelChange}
                            onKeyDown={handleKeyPress}
                            error={Boolean(feeError)}
                            helperText={feeError}
                        />
                    </>)}

                </DialogContent>
                <DialogActions style={{ marginBottom: "15px", marginRight: "15px" }}>
                    <Button onClick={handleCloseDialog} color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateFees} variant="contained" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AthleteClubChangeFeesConfiguration