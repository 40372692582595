import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid, FormControlLabel, Radio, RadioGroup, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, CircularProgress, Snackbar } from "@mui/material";
import BlankCard from "src/components/shared/BlankCard";
import TableHead from "src/components/table-head";
import DataTable from "src/components/table/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import { IconEye } from "@tabler/icons-react";
import BackLink from "src/components/back-link";
import APP_ROUTES from "src/routes/routePaths";
import FileUpload from "src/components/upload-file/UploadFile";
import { allowedFormats, maxFileSize } from "src/utils/fileUploadConfig";
import VerticalExample from "src/assets/images/athlete-id-card/V-ID.png";
import HorizontalExample from "src/assets/images/athlete-id-card/H-ID.png";
import { RequiredStar } from "src/components/required-star";
import axiosServices from "src/utils/axios";
import { notifyMessage } from "src/utils/toastNotify";

interface ViewUploaded {
    url: string | null;
    type: string | null;
}

const DistrictClubAthleteIdCardConfigurations = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [actionId, setActionId] = useState<number | null>(null);
    const [signatureImage, setSignatureImage] = useState<File | null>(null);
    const [backgroundImage, setBackgroundImage] = useState<File | null>(null);
    const [stampImage, setStampImage] = useState<File | null>(null);
    const [signatureImageURL, setSignatureImageURL] = useState<ViewUploaded>({ url: null, type: null });
    const [backgroundImageURL, setBackgroundImageURL] = useState<ViewUploaded>({ url: null, type: null });
    const [stampImageURL, setStampImageURL] = useState<ViewUploaded>({ url: null, type: null });
    const [frameType, setFrameType] = useState('horizontal');
    const [isCollect, setIsCollect] = useState(false);
    const [openIdFormatDialog, setOpenIdFormatDialog] = useState(false);
    const [selectedFrameType, setSelectedFrameType] = useState('horizontal');
    const [imageRows, setImageRows] = useState<any[]>([]);
    const [openShowImageDialog, setOpenShowImageDialog] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [clubId, setClubId] = useState<number | null>(null)
    const [buttonLabel, setButtonLabel] = useState<string>("Update")
    console.log("clubId", clubId);


    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSignatureImageURL({ url: null, type: null });
        setBackgroundImageURL({ url: null, type: null });
        setStampImageURL({ url: null, type: null });
        setSignatureImage(null);
        setBackgroundImage(null);
        setStampImage(null);
        setIsCollect(false);
        fetchImages();
    };

    const fetchImages = async () => {
        try {
            const response = await axiosServices.get(`/district/configuration/view-athlete-id-card-configuration`);
            const data = response.data.data;
            console.log("Images Response", data);

            setSignatureImage(data.identity_card_signature || null);
            setStampImage(data.identity_card_stamp || null);
            setBackgroundImage(data.identity_card_bg_image || null);
            setFrameType(data.identity_card_format || null);
            setSelectedFrameType(data.identity_card_format || null);
            setActionId(data.identity_card_configuration_id || null);
            setIsCollect(data.is_collect == 0 ? false : true || null);
            setClubId(data.club_id)
        } catch (error: any) {
            notifyMessage.error(error.response.data.message)
            setButtonLabel("Add")
            setClubId(error.response.data?.data?.club_id || null)
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    useEffect(() => {
        generateFileUrl(signatureImage, setSignatureImageURL);
    }, [signatureImage]);

    useEffect(() => {
        generateFileUrl(backgroundImage, setBackgroundImageURL);
    }, [backgroundImage]);

    useEffect(() => {
        generateFileUrl(stampImage, setStampImageURL);
    }, [stampImage]);

    const handleFileUpload = (fileType: 'idCardStamp' | 'idCardBackground' | 'idCardSignature') => (files: File[] | null) => {
        if (files && files.length > 0) {
            const file = files[0];
            console.log("file", file);


            // Update the state based on fileType
            const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/jpg'];
            if (allowedTypes.includes(file.type)) {
                if (fileType === 'idCardStamp') {
                    setStampImage(file);
                } else if (fileType === 'idCardBackground') {
                    setBackgroundImage(file);
                } else if (fileType === 'idCardSignature') {
                    setSignatureImage(file);
                }
            } else {
                notifyMessage.error('Invalid file type. Please upload a JPG, JPEG, PNG, or SVG image.');
            }
        }
    };

    const generateFileUrl = (file: File | string | null, setUrl: (viewUploaded: ViewUploaded) => void) => {
        if (file instanceof File) {
            const url = URL.createObjectURL(file);
            const type = file.type;
            setUrl({ url, type });

            return () => URL.revokeObjectURL(url);
        } else if (typeof file === 'string') {
            const type = getFileTypeFromUrl(file);
            setUrl({ url: file, type });
        } else {
            setUrl({ url: null, type: null });
        }
    };

    const getFileTypeFromUrl = (url: string): string | null => {
        const extension = url.split('.').pop()?.toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'application/pdf';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            default:
                return null;
        }
    };

    const handelIdFormat = () => {
        setOpenIdFormatDialog(true);
    };

    const handelIdFormatClose = () => {
        setOpenIdFormatDialog(false);
    }

    const imageColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sr.No', flex: 0.5, align: 'center', headerAlign: 'center' },
        { field: 'image', headerName: 'Image', flex: 1, align: 'center', headerAlign: 'center' },
        {
            field: 'view',
            headerName: 'View',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <>
                    <Tooltip title="Preview Image">
                        <IconButton aria-label="view" onClick={() => handleOpenShowImageDialog(params.value)}>
                            <IconEye size="22" stroke={1.4} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    useEffect(() => {
        setImageRows([
            { id: 1, image: 'ID Card Background Image', view: backgroundImageURL.url || null },
            { id: 2, image: 'ID Card Authority Signature', view: signatureImageURL.url || null },
            { id: 3, image: 'ID Card Club Stamp', view: stampImageURL.url || null },
        ]);
    }, [signatureImageURL, backgroundImageURL, stampImageURL]);

    const handleSave = async () => {
        if (!signatureImage || !frameType) {
            setSnackbarMessage("Please upload the Signature Image and select the Frame Type before saving.");
            setSnackbarOpen(true);

            return;
        }
        setIsLoading(true); // Show loader

        try {
            const formData = new FormData();

            if (signatureImage instanceof File) {
                formData.append('identityCardSignature', signatureImage);
            }
            if (backgroundImage instanceof File) {
                formData.append('identityCardBgImage', backgroundImage);
            }
            if (stampImage instanceof File) {
                formData.append('identityCardStamp', stampImage);
            }
            formData.append('identityCardFormat', frameType);
            formData.append('isCollect', isCollect ? '1' : '0');

            const response = await axiosServices.put(`/district/configuration/update-id-card-configuration/${clubId}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.data.success) {
                notifyMessage.success(response.data.message);
            }
            handleCloseDialog();

            // console.log("API response", response);
        } catch (error: any) {
            // console.log("Error", error);
        } finally {
            setIsLoading(false); // Hide loader
        }

        // console.log('Signature Image:', signatureImage);
        // console.log('Background Image:', backgroundImage);
        // console.log('Stamp Image:', stampImage);
        // console.log('Frame Type:', frameType);

    };


    const handleOpenShowImageDialog = (url: string | null) => {
        // console.log("URL", url);
        setSelectedImageUrl(url);
        setOpenShowImageDialog(true);
    };

    const handleCloseShowImageDialog = () => {
        setOpenShowImageDialog(false);
        setSelectedImageUrl(null);
    };

    return (
        <>
            <BackLink title="Back to Configurations Tabs" route={`${APP_ROUTES.DISTRICT_CLUB_ADMIN_CONFIGURATIONS}`} />
            <BlankCard>
                <TableHead title="Athlete Id Card Basic Template" />

                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            The following template is necessary for the Athlete ID Card setup.
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 3 }}>
                        <Typography variant="body1">
                            1. Please ensure that all fields are filled out correctly before updating.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            2. Ensure images are in the correct format (JPEG/PNG) and meet the specified size requirements.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            3. Review all information carefully before saving to avoid any errors.
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 3 }}>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            You will need to upload the following three images:
                        </Typography>
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                • ID Card Authority Signature <span style={{ fontWeight: 'bold' }}>(Note: Signature is mandatory)</span>
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                • ID Card Background Image/Watermark
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                • ID Card Stamp/Seal
                            </Typography>
                        </Box>
                    </Box>

                    <Typography variant="body1" sx={{ mb: 2 }}>
                        After uploading the images, you will choose the ID Card frame: vertical or horizontal.
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 3 }}>
                        Below is the list of images you have provided. You can update them by clicking on the Update button.
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>
                                Currently ID Card frame chosen is in: {selectedFrameType.charAt(0).toUpperCase() + selectedFrameType.slice(1)} format.
                            </Typography>
                            <Tooltip title="View Id Card Format">
                                <IconButton onClick={handelIdFormat}>
                                    <IconEye size="22" stroke={1.4} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                            {buttonLabel}
                        </Button>
                    </Box>

                    <Grid>
                        <DataTable rows={imageRows} columns={imageColumns} tableId="table91" />
                    </Grid>
                </Box>

            </BlankCard>

            {/* Dialog Box */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>Update ID Card Template</DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FileUpload
                                    title="ID Card Authority Signature (Mandatory)"
                                    required={true}
                                    onFileUpload={handleFileUpload('idCardSignature')}
                                    name="clubLogo"
                                    allowedFormats={allowedFormats.OnlyImage}
                                    maxFileSize={maxFileSize.TwoMB} // 2 MB
                                    viewUploaded={signatureImageURL}
                                />
                            </Grid>

                            <Grid item xs={12} lg={7} sm={7}>
                                <FileUpload
                                    title="ID Card Background/Watermark"
                                    required={false}
                                    onFileUpload={handleFileUpload('idCardBackground')}
                                    name="clubLogo"
                                    allowedFormats={allowedFormats.OnlyImage}
                                    maxFileSize={maxFileSize.TwoMB} // 2 MB
                                    viewUploaded={backgroundImageURL}
                                />
                            </Grid>

                            <Grid item xs={12} lg={5} sm={5}>
                                <FileUpload
                                    title="ID Card Stamp/Seal"
                                    required={false}
                                    onFileUpload={handleFileUpload('idCardStamp')}
                                    name="clubLogo"
                                    allowedFormats={allowedFormats.OnlyImage}
                                    maxFileSize={maxFileSize.TwoMB} // 2 MB
                                    viewUploaded={stampImageURL}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel>Is physical ID card will be provided by the club?<RequiredStar /></CustomFormLabel>
                                <RadioGroup
                                    row
                                    aria-label="isCollect"
                                    name="isCollect"
                                    value={isCollect}
                                    onChange={(e) => setIsCollect(e.target.value === 'true')}
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomFormLabel>Select ID Card Frame Orientation: <RequiredStar /></CustomFormLabel>
                                <RadioGroup
                                    value={frameType}
                                    onChange={(event) => setFrameType(event.target.value)}
                                    row
                                >
                                    <FormControlLabel value="vertical" control={<Radio />} label="Vertical" />
                                    <FormControlLabel value="horizontal" control={<Radio />} label="Horizontal" />
                                </RadioGroup>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 2 }}>
                                {frameType === 'vertical' && (
                                    <Box>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Visual Representation of Vertical Orientation:
                                        </Typography>
                                        <img
                                            src={VerticalExample}
                                            alt="Vertical Example"
                                            style={{ maxWidth: '100%', height: 'auto' }}
                                        />
                                    </Box>
                                )}

                                {frameType === 'horizontal' && (
                                    <Box>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Visual Representation of Horizontal Orientation:
                                        </Typography>
                                        <img
                                            src={HorizontalExample}
                                            alt="Horizontal Example"
                                            style={{ maxWidth: '100%', height: 'auto' }}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions style={{ marginBottom: '15px', marginRight: '15px' }}>
                    <Button onClick={handleCloseDialog} color="error">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        disabled={isLoading}
                        variant="contained"
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            width: isLoading ? '120px' : 'auto',
                            paddingRight: isLoading ? '50px' : '16px'
                        }}
                    >
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                }}
                            />
                        )}
                        Submit
                    </Button>
                </DialogActions>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    ContentProps={{
                        sx: { backgroundColor: '#FF6347' } // Example: tomato color
                    }}
                />
            </Dialog>

            <Dialog open={openIdFormatDialog} onClose={handelIdFormatClose}>
                <DialogTitle>Selected ID Card Template</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            {selectedFrameType === 'vertical' && (
                                <Box textAlign="center">
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        Visual Representation of Vertical Orientation:
                                    </Typography>
                                    <img
                                        src={VerticalExample}
                                        alt="Vertical Example"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                </Box>
                            )}

                            {selectedFrameType === 'horizontal' && (
                                <Box textAlign="center">
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        Visual Representation of Horizontal Orientation:
                                    </Typography>
                                    <img
                                        src={HorizontalExample}
                                        alt="Horizontal Example"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions style={{ marginBottom: '15px', marginRight: '15px' }}>
                    <Button onClick={handelIdFormatClose} color="error">
                        Close
                    </Button>
                </DialogActions>
            </Dialog >


            <Dialog open={openShowImageDialog} onClose={handleCloseShowImageDialog} maxWidth="sm">
                <DialogTitle>Image Preview</DialogTitle>
                <DialogContent>
                    {selectedImageUrl ? (
                        <img src={selectedImageUrl} alt="Preview" style={{ width: '100%', height: 'auto' }} />
                    ) : (
                        'No image to display'
                    )}
                </DialogContent>
                <DialogActions style={{ marginBottom: '15px', marginRight: '15px' }}>
                    <Button onClick={handleCloseShowImageDialog} color="error">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default DistrictClubAthleteIdCardConfigurations;
