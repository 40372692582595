import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Button, Avatar, Stack, styled, Tab, Paper, InputBase, IconButton, Skeleton, Theme, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import PinDropIcon from "@mui/icons-material/PinDrop";
import SubjectIcon from "@mui/icons-material/Subject";
import VisibilityIcon from "@mui/icons-material/Visibility";
import userimg from "src/assets/images/profile/user-1.jpg";
import BlankCard from "src/components/shared/BlankCard";
import { Link } from "react-router-dom";
import APP_ROUTES from "src/routes/routePaths";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { AppDispatch, AppState } from "src/store/Store";
//import { fetchAthleteData,  setShooterId} from "src/store/athlete-register-formdata/AthleteViewAndEdit";
import { fetchClubAthleteData, setShooterId } from "src/store/clubRegister/ClubAthleteViewEdit";
import { useDispatch } from "react-redux";
import { formatDate } from "src/utils/basicFormaters";
import { IconMapPin } from "@tabler/icons-react";
import ContactsIcon from '@mui/icons-material/Contacts';
import { fetchSingleDistrictAthleteRequest } from "src/store/reducers/atheleteSlice";

const DistrictClubProfileView = () => {
    const { athleteId } = useParams();
    const athleteRegisteredData: any = useSelector((state: AppState) => state.athleteFormData);
    const dispatch: AppDispatch = useDispatch();
    const athleteResponse = useSelector((state: AppState) => state.athelete.DistrictAthlete)
    const isLoading = athleteResponse ? false : true
    console.log("athleteResponse", athleteResponse);
    const ProfileImage = styled(Box)(() => ({
        backgroundImage: "linear-gradient(#50B2FC,#F44C66)",
        borderRadius: "50%",
        width: "145px",
        height: "145px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
    }));
    const [value, setValue] = useState("1");
    const [selectedDocument, setSelectedDocument] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
        setLoading(false);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleViewDocument = (documentType: string) => {
        setSelectedDocument(documentType);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setLoading(true);
        setOpenDialog(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        // console.log(token);

        if (token) {
            const decodedToken: any = jwtDecode(token);
            // console.log(decodedToken, "decoded");
            if (decodedToken) {
                const { state, email, role, userId } = decodedToken;
                dispatch(setShooterId(userId));
                dispatch(fetchSingleDistrictAthleteRequest(userId))
            }
        }
    }, [dispatch]);

    if (isLoading) {
        return (
            <>
                <Skeleton variant="text" animation="wave" width="100%" height={"20vh"}></Skeleton>
                <Skeleton variant="rectangular" sx={{ mb: 1 }} animation="wave" width="100%" height={"20vh"}></Skeleton>
                <Skeleton variant="rectangular" animation="wave" width="100%" height={"30vh"}></Skeleton>
            </>
        );
    }

    const formattedDateOfBirth = formatDate(athleteResponse?.dOB);

    const documentImageUrl = {
        'address_proof': athleteResponse.address_proof,
        'profile_photo': athleteResponse.profile_photo,
        'safety_course_document': athleteResponse.safety_course_document,
        'birth_proof': athleteResponse.birth_proof,
        'action_photo': athleteResponse.action_photo,
        'passport_proof': athleteResponse.passport,
    }[selectedDocument];

    return (
        <>
            <Link style={{ display: "flex", alignItems: "center", color: "#000", gap: "10px", marginBottom: "16px" }} to={`${APP_ROUTES.ATHLETE_DASHBOARD}`}>
                {" "}
                <KeyboardBackspaceIcon /> Back to the Dashboard
            </Link>
            <BlankCard>
                <Box sx={{ height: "140px", minHeight: "140px", borderRadius: "none", backgroundColor: (theme) => theme.palette.grey[100] }}></Box>
                <Grid container spacing={0} justifyContent="center" alignItems="center">
                    <Grid
                        item
                        lg={4}
                        sm={12}
                        md={5}
                        xs={12}
                        sx={{
                            order: {
                                xs: "2",
                                sm: "2",
                                lg: "1",
                            },
                        }}
                    >
                        <Stack direction="column" textAlign="left" justifyContent="center" gap={6} m={2}>
                            <Box>
                                <span style={{ color: "#000", display: "flex" }}>
                                    Email:
                                    <Typography color="textSecondary" ml={1}>
                                        {athleteResponse?.email ? athleteResponse.email : "NA"}
                                    </Typography>
                                </span>
                                <span style={{ color: "#000", display: "flex" }}>
                                    Contact Number:
                                    <Typography color="textSecondary" ml={1}>
                                        {athleteResponse?.phone || "NA"}
                                    </Typography>
                                </span>
                                <span style={{ color: "#000", display: "flex" }}>
                                    Shooter ID:
                                    <Typography color="textSecondary" ml={1}>
                                        {athleteResponse?.membershipId || "NA"}
                                    </Typography>
                                </span>
                            </Box>
                        </Stack>
                    </Grid>
                    {/* about profile */}
                    <Grid
                        item
                        lg={4}
                        sm={12}
                        xs={12}
                        sx={{
                            order: {
                                xs: "1",
                                sm: "1",
                                lg: "2",
                            },
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            textAlign="center"
                            justifyContent="center"
                            sx={{
                                mt: "-85px",
                            }}
                        >
                            <Box>
                                <ProfileImage>
                                    <Avatar
                                        src={athleteResponse?.profile_photo}
                                        alt={userimg}
                                        sx={{
                                            borderRadius: "50%",
                                            width: "140px",
                                            height: "140px",
                                            border: "4px solid #fff",
                                        }}
                                    />
                                </ProfileImage>
                                <Box mt={2}>
                                    <Typography fontWeight={600} variant="h5">
                                        {athleteResponse?.first_name + " " + athleteResponse?.last_name}
                                    </Typography>
                                    <Typography color="textSecondary" variant="caption" fontWeight={600}>
                                        Membership Status :
                                    </Typography>
                                    <Typography color={athleteResponse?.status == "Approved" ? "primary" : athleteResponse?.status == "Rejected" ? "error" : "#ff9800"} variant="caption" fontWeight={600}>
                                        {" "}
                                        {athleteResponse?.status == "Approved"
                                            ? 'APPROVED'
                                            : athleteResponse?.status == "Rejected"
                                                ? 'REJECTED'
                                                : 'PENDING'}
                                    </Typography>
                                    {athleteResponse?.status == "Rejected" && (
                                        <>
                                            <br />
                                            <Button variant="outlined" color="error" size="small" onClick={handleClickOpen}>
                                                Reason
                                            </Button>
                                            <Dialog open={open} onClose={handleClose}>
                                                <DialogTitle>Rejected Reason :</DialogTitle>
                                                <DialogContent>
                                                    <Typography>
                                                        {athleteResponse?.in_active_reason || 'No reason provided.'}
                                                    </Typography>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose} color="success">
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </>
                                    )}

                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {/* friends following buttons */}
                    <Grid
                        item
                        lg={4}
                        sm={12}
                        xs={12}
                        sx={{
                            order: {
                                xs: "3",
                                sm: "3",
                                lg: "3",
                            },
                        }}
                    >
                        <Stack direction={"row"} gap={2} alignItems="center" justifyContent="flex-end" my={2} px={3}>
                            <Button color="info" variant="outlined" component={Link} to={`${APP_ROUTES.ATHLETE_CLUB_EDIT}/${athleteId}`} >
                                Edit Profile
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <Box className="athleteBox" mt={1} sx={{ mt: 4, display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <Box sx={{ width: "40%", paddingX: "20px", paddingTop: "25px" }}>
                        <Typography fontWeight={600} variant="h5" mb={2}>
                            Club Info
                        </Typography>
                        <Stack direction="column" gap={0.4} alignItems="flex-start" mb={0.7}>
                            <span style={{ color: "#000", display: "flex" }}>
                                Club Name:
                                <Typography mx={1} color="textSecondary">
                                    {athleteResponse?.clubName || "NA"}
                                </Typography>
                            </span>
                            <span style={{ color: "#000", display: "flex" }}>
                                Membership Type:
                                <Typography mx={1} color="textSecondary">
                                    {athleteResponse?.membership_type || "NA"}
                                </Typography>
                            </span>
                            <span style={{ color: "#000", display: "flex" }}>
                                Events:
                                <Typography mx={1} color="textSecondary" sx={{ textTransform: "capitalize" }}>
                                    {athleteResponse?.event?.join(", ") || "NA"}{" "}
                                </Typography>
                            </span>
                        </Stack>
                        {/* <Stack direction="row" gap={2} alignItems="center" mb={1}>
                <IconMapPin size="21" />
                <Typography variant="h6">Newyork, USA - 100001</Typography>
            </Stack> */}
                    </Box>
                    <Box
                        justifyContent={"end"}
                        display="flex"
                        flexDirection="column"
                        sx={{ overflow: "auto", paddingLeft: "10px", borderLeft: "1px solid #ccc", width: { xs: "333px", sm: "auto", md: "70%" } }}
                    >
                        <TabContext value={value}>
                            <Box sx={{ borderRadius: "0" }}>
                                <TabList sx={{ color: (theme: Theme) => theme.palette.grey[100] }} onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab sx={{ width: "25%" }} icon={<ContactsIcon />} iconPosition="start" label="Athlete Info" value="1" />
                                    <Tab sx={{ width: "25%" }} icon={<IconMapPin />} iconPosition="start" label="Location" value="2" />
                                    <Tab sx={{ width: "25%" }} icon={<SubjectIcon />} iconPosition="start" label="Documents" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Typography variant="body1"> Blood Group : {athleteResponse?.blood_group ? athleteResponse.blood_group : 'NA'}</Typography>
                                <Typography variant="body1"> Aadhar : {athleteResponse?.aadhar_card ? athleteResponse.aadhar_card : 'NA'}</Typography>
                                {/* <Typography variant="body1"> Membership : {athleteResponse.membership_type}</Typography> */}
                                <Typography variant="body1"> Gender : {athleteResponse?.gender || "NA"}</Typography>
                                <Typography variant="body1"> DOB : {" "}{formattedDateOfBirth ? formattedDateOfBirth : "NA"}{" "}</Typography>
                                <Typography variant="body1"> Education : {athleteResponse?.education || "NA"}</Typography>
                                <Typography variant="body1"> Phone No : {athleteResponse?.phone || "NA"}</Typography>
                                <Typography variant="body1"> Alternative No : {athleteResponse?.alternate_no || "NA"}</Typography>
                            </TabPanel>
                            <TabPanel value="2">
                                <Typography variant="body1"> Address : {athleteResponse?.address || "NA"}</Typography>
                                <Typography variant="body1"> City : {athleteResponse?.city || "NA"}</Typography>
                                <Typography variant="body1"> Pincode : {athleteResponse?.pincode || "NA"}</Typography>
                                <Typography variant="body1"> State : {athleteResponse?.stateName || "NA"}</Typography>

                            </TabPanel>
                            <TabPanel value="3">
                                <Stack direction={"row"} spacing={1}>
                                    <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 160, maxWidth: 300 }}>
                                        <InputBase sx={{ ml: 1, flex: 1 }} value="Profile Photo" />
                                        <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument('profile_photo')}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Paper>
                                    <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 160, maxWidth: 300 }}>
                                        <InputBase sx={{ ml: 1, flex: 1 }} value="Address Proof" />
                                        <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument('address_proof')}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Paper>
                                    <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 250, maxWidth: 300 }}>
                                        <InputBase sx={{ ml: 1, flex: 1 }} value="Safety Course Document" />
                                        <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument('safety_course_document')}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Paper>
                                </Stack>
                                <br />
                                <Stack direction={"row"} spacing={1}>
                                    <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 160, maxWidth: 300 }}>
                                        <InputBase sx={{ ml: 1, flex: 1 }} value="Birth Proof" />
                                        <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument('birth_proof')}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Paper>
                                    <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 160, maxWidth: 300 }}>
                                        <InputBase sx={{ ml: 1, flex: 1 }} value="Action Photo" />
                                        <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument('action_photo')}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Paper>
                                    <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 180, maxWidth: 250 }}>
                                        <InputBase sx={{ ml: 1, flex: 1 }} value="Passport Proof" />
                                        <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleViewDocument('passport_proof')}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Paper>
                                </Stack>

                                <Dialog open={openDialog} onClose={handleCloseDialog}>
                                    <DialogTitle>
                                        {{
                                            'address_proof': 'Address Proof',
                                            'profile_photo': 'Profile Photo',
                                            'safety_course_document': 'Safety Course Proof',
                                            'birth_proof': 'Birth Proof',
                                            'action_photo': 'Action Photo',
                                            'passport_proof': 'Passport Proof'
                                        }[selectedDocument] || ''}
                                    </DialogTitle>

                                    <DialogContent>
                                        {documentImageUrl ? (
                                            <>
                                                {loading && (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                                        <CircularProgress />
                                                    </div>
                                                )}
                                                <img
                                                    src={documentImageUrl}
                                                    alt={{
                                                        'address_proof': 'Address Proof',
                                                        'profile_photo': 'Profile Photo',
                                                        'safety_course_document': 'Safety Course Document',
                                                        'birth_proof': 'Birth Proof',
                                                        'action_photo': 'Action Photo',
                                                        'passport_proof': 'Passport Proof'
                                                    }[selectedDocument]}
                                                    style={{
                                                        display: loading ? 'none' : 'block',
                                                        maxWidth: '100%',
                                                        maxHeight: '100%',
                                                        height: 'auto',
                                                        width: 'auto'
                                                    }}
                                                    onLoad={handleImageLoad}
                                                />
                                            </>
                                        ) : (
                                            <p>Image not available...</p>
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDialog}>Close</Button>
                                    </DialogActions>
                                </Dialog>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Box>
            </BlankCard>
        </>
    );
};
export default DistrictClubProfileView;
