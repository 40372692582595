import { createSlice } from "@reduxjs/toolkit";

interface atheleteState {
  athleteList: any,
  districtAthleteList: any,
  Athlete: any,
  DistrictAthlete: any,
  loading: boolean
  error: any,
  response: any,


}

const initialState: atheleteState = {
  athleteList: [],
  districtAthleteList: [],
  Athlete: [],
  DistrictAthlete: [],
  loading: false,
  response: null,
  error: null,

};

export const atheleteSlice = createSlice({
  name: 'athelete',
  initialState,
  reducers: {

    fetchAthleteListStart: state => {
      state.loading = true;
    },
    fetchAthleteListSuccess: (state, action) => {
      state.loading = false;
      state.athleteList = action.payload;
      state.error = null;
    },
    fetchAthleteListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // district admin shooters
    fetchDistrictAthleteListStart: state => {
      state.loading = true;
    },
    fetchDistrictAthleteListSuccess: (state, action) => {
      state.loading = false;
      state.districtAthleteList = action.payload;
      state.error = null;
    },
    fetchDistrictAthleteListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    updateAthleteStatusStart: (state, action) => {
      state.loading = true;
    },
    updateAthleteStatusSuccess: (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.error = null;
    },
    updateAthleteStatusFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchSingleAthleteRequest: (state, action) => {
      state.loading = false
    },
    fetchSingleAthleteSuccess: (state, action) => {
      state.Athlete = action.payload
      state.loading = false
    },
    fetchSingleAthleteFailure: (state, action) => {
      state.loading = true
      state.error = action.payload
    },
    // District athlete
    fetchSingleDistrictAthleteRequest: (state, action) => {
      state.loading = false
    },
    fetchSingleDistrictAthleteSuccess: (state, action) => {
      state.DistrictAthlete = action.payload
      state.loading = false
    },
    fetchSingleDistrictAthleteFailure: (state, action) => {
      state.loading = true
      state.error = action.payload
    },

  },
});

export const {

  fetchAthleteListStart,
  fetchAthleteListSuccess,
  fetchAthleteListFailure,
  fetchDistrictAthleteListStart,
  fetchDistrictAthleteListSuccess,
  fetchDistrictAthleteListFailure,
  updateAthleteStatusStart,
  updateAthleteStatusSuccess,
  updateAthleteStatusFailure,
  fetchSingleAthleteRequest,
  fetchSingleAthleteSuccess,
  fetchSingleAthleteFailure,
  fetchSingleDistrictAthleteRequest,
  fetchSingleDistrictAthleteSuccess,
  fetchSingleDistrictAthleteFailure,

} = atheleteSlice.actions;


export default atheleteSlice.reducer;
