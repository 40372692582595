import React, { useState } from "react";
import { Box } from "@mui/material";
import BlankCard from "src/components/shared/BlankCard";
import TableHead from "src/components/table-head";
import idCard from "src/assets/images/athlete-id-card/shooteridcard_sample.png";
import DistrictAthleteIDCardGenerator from "./DistrictAthleteIdCardGenerator";
import { useSelector } from "react-redux";
import { AppState } from "src/store/Store";

const DistrictAthleteIdCard = () => {
    const athleteId: any = useSelector((state: AppState) => state.viewClubAthlete.athleteId);

    return (
        <>
            <BlankCard>
                <TableHead title="Download Athlete Id Card" />
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <DistrictAthleteIDCardGenerator athleteId={athleteId} />
                </Box>
            </BlankCard>
        </>
    );
};

export default DistrictAthleteIdCard;