import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AthleteFormDataState {
  slideOne: {
    clubName: any;
    stateUnit: any;
    firstName: string;
    lastName: string;
    playingEvents: {
      rifle: boolean;
      pistol: boolean;
      shotgun: boolean;
      bigbore: boolean;
    };
    education: string;
    dateOfBirth: Date | null;
    email: string;
    contactNumber: string;
    alternateContactNumber: string;
    gender: string;
    bloodGroup: string;
    safetyCourse: boolean;
    safetyCourseDocument: any;
    profilePhoto: any;
    addressProof: any;
  };
  slideTwo: {
    //clubName: string;
    membership: string;
    aadhar: string;
    address: string;
    stateName: string;
    cityName: string;
    pincode: string;
    agreement: boolean;
    conscent: boolean;
    passport: any;
    birthProof: any;
    actionPhoto: any;
  };
  athleteType: "club_athlete" | "district_club_athlete";
}

const initialState: AthleteFormDataState = {
  slideOne: {
    clubName: "",
    stateUnit: "",
    firstName: "",
    lastName: "",
    playingEvents: {
      rifle: false,
      pistol: false,
      shotgun: false,
      bigbore: false,
    },
    education: "",
    dateOfBirth: null,
    email: "",
    contactNumber: "",
    alternateContactNumber: "",
    gender: "",
    bloodGroup: "",
    safetyCourse: false,
    safetyCourseDocument: null,
    profilePhoto: null,
    addressProof: null,
  },
  slideTwo: {
    //clubName: "",
    membership: "",
    aadhar: "",
    address: "",
    stateName: "",
    cityName: "",
    pincode: "",
    agreement: false,
    conscent: false,
    passport: null,
    birthProof: null,
    actionPhoto: null,
  },
  athleteType: "club_athlete",
};

const athleteFormDataSlice = createSlice({
  name: "athleteFormClubData",
  initialState,
  reducers: {
    setSlideOneFormData: (state, action) => {
      // state.slideOne = { ...state.slideOne, ...action.payload };
      return {
        ...state,
        slideOne: {
          ...state.slideOne,
          ...action.payload,
        },
      };
    },
    setSlideTwoFormData: (state, action) => {
      // state.slideTwo = { ...state.slideTwo, ...action.payload };
      return {
        ...state,
        slideTwo: {
          ...state.slideTwo,
          ...action.payload,
        },
      };
    },
    resetAthleteData: () => initialState,
  },
});

export const { setSlideOneFormData, setSlideTwoFormData, resetAthleteData } = athleteFormDataSlice.actions;
export default athleteFormDataSlice.reducer;
