import React, { useState, useEffect, ChangeEvent } from "react";
import { Box, Grid, MenuItem, Stack, Typography } from "@mui/material";
import PageContainer from "src/components/page-container/PageContainer";
import BlankCard from "src/components/shared/BlankCard";
import TableHead from "src/components/table-head";
import DataTable from "src/components/table/TableComponent";
import axiosServices from "src/utils/axios";
import { GridColDef } from "@mui/x-data-grid";
import { RequiredStar } from "src/components/required-star";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import { ISelectPaymentLogs } from "src/types/PaymentLogs";
import { notifyMessage } from "src/utils/toastNotify";
import { useSelector } from "react-redux";
import { AppState } from "src/store/Store";

const DistrictPaymentLogsAdmin = () => {
    const [rows, setRows] = useState<any[]>([]);
    const [events, setEvents] = useState<any>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageNo, setPageNo] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [name, setName] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [productinfo, setProductinfo] = useState<string>('');
    const [club, setClub] = useState<string>('');
    const [txnid, setTxnid] = useState<string>('');
    const [lastPage, setLastPage] = useState(false);
    const tenantList = useSelector((state: AppState) => state.tenent.tenantList);
    console.log("tenant list", tenantList);


    useEffect(() => {
        fetchPaymentLogs();
        fetchData();
    }, []);

    const fetchPaymentLogs = async () => {
        try {
            const response = await axiosServices.get(`${process.env.REACT_APP_BASE_URL}/club/payment-event-types`);
            const productInfos = response.data.data.map((item: { productinfo: string }) => item.productinfo);
            setEvents(productInfos);
        } catch (error) {
            console.error("Error fetching payment event types:", error);
        }
    };

    const fetchClubs = async () => {
        try {
            const response = await axiosServices.get(`${process.env.REACT_APP_BASE_URL}/club/payment-event-types`);
            const productInfos = response.data.data.map((item: { productinfo: string }) => item.productinfo);
            setEvents(productInfos);
        } catch (error) {
            console.error("Error fetching payment event types:", error);
        }
    }

    const fetchData = async () => {
        try {
            const response = await axiosServices.get(`${process.env.REACT_APP_BASE_URL}/district/payment-log/view-payment-logs`, {
                params: {
                    pageNo,
                    name,
                    status,
                    productinfo,
                    rowsPerPage,
                    txnid,
                    club
                }
            });
            // console.log("paymentLogs", response.data.data);
            const paymentLogs = response.data.data.paymentLogs;


            const updatedRows = paymentLogs.map((row: any, index: any) => ({
                srNo: (pageNo - 1) * rowsPerPage + index + 1,
                ...row,
            }));

            setRows(updatedRows);
            setTotalCount(response.data.data.totalPages);
            setLastPage(response.data.data.lastPage);
        } catch (error: any) {
            console.error("Failed to fetch data", error);
            // notifyMessage.error(error?.response?.data?.message);
            if (error?.response?.data?.message) {
                setRows([]);
                setTotalCount(0);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [pageNo, rowsPerPage, name, status, productinfo, txnid, club]);

    // // console.log("Events:", events);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const columns: GridColDef[] = [
        {
            field: 'srNo',
            headerName: 'Sr. No',
            flex: 0.5,
            renderCell: (params) => (
                <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {params.value}
                </Typography>
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            // align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {params.value}
                </Typography>
            )
        },
        // {
        //     field: 'contact',
        //     headerName: 'Contact',
        //     flex: 1,
        //     align: 'center',
        //     headerAlign: 'center',
        //     renderCell: (params) => (
        //         <Box
        //             display="flex"
        //             alignItems="center"
        //             sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
        //         >
        //             <Typography style={{ whiteSpace: 'pre-wrap'/* , wordBreak: 'break-word' */ }}>
        //                 {params.row.email && params.row.phone ? `${params.row.email} / ${params.row.phone}` : ''}
        //             </Typography>
        //         </Box>
        //     )
        // },
        {
            field: 'productinfo',
            headerName: 'Product Info',
            flex: 1.4,
            // align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {params.value}
                </Typography>
            )
        },
        {
            field: 'txnid',
            headerName: 'Transaction Id',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Box
                    display="flex"
                    // alignItems="center"
                    sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
                >
                    <Typography>
                        {params.value}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.8,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Typography
                    style={{
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        color: params.value === "success" ? '#4cd1b4' : '#f58f7a'
                    }}
                >
                    {params.value === "success" ? "Success" : "Failure"}
                </Typography>
            )
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {params.value} ₹
                </Typography>
            )
        },
        {
            field: 'created_at',
            headerName: 'Payment Date',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const date = params.value ? formatDate(params.value) : '';

                return (
                    <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                        {date}
                    </Typography>
                );
            }
        },
        {
            field: 'club_name',
            headerName: 'Club Name',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {params.value?.replaceAll("_", " ")
                        ?.split(" ")
                        ?.map((data: any) => data.charAt(0).toUpperCase() + data.slice(1))
                        ?.join(" ")}
                </Typography>
            )
        },
    ];

    const handlePageChange = (page: number) => {
        setPageNo(page + 1);
    };

    const handlePageSizeChange = (size: number) => {
        setRowsPerPage(size);
        // setPageNo(1);
    };

    return (
        <>
            <PageContainer title="Payment Logs page" description="this is Payment Logs page">
                <BlankCard>
                    <Box>
                        <TableHead title="Payment Logs" />
                        <Grid container spacing={3} sx={{ paddingLeft: "15px", paddingRight: "15px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Grid item xs={12} lg={6}>
                                <CustomTextField
                                    select
                                    label={
                                        <span>
                                            Events
                                        </span>
                                    }
                                    name="paymentevent"
                                    value={productinfo}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => { setProductinfo(e.target.value); setPageNo(1); }}
                                    fullWidth
                                    margin="normal"
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {events.map((option: any) => (
                                        <MenuItem key={option} value={option}>
                                            {option
                                                .split(' ')
                                                .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
                                                .join(' ')
                                            }
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <CustomTextField
                                    label="Name"
                                    name="name"
                                    value={name}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => { setName(e.target.value); setPageNo(1); }}
                                    fullWidth
                                    margin="normal"
                                >
                                </CustomTextField>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} sx={{ paddingLeft: "15px", paddingRight: "15px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Grid item xs={12} lg={6}>
                                <CustomTextField
                                    label="Transaction Id"
                                    name="transactionId"
                                    value={txnid}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => { setTxnid(e.target.value); setPageNo(1); }}
                                    fullWidth
                                    margin="normal"
                                >
                                </CustomTextField>
                            </Grid>

                            <Grid item xs={12} lg={3}>
                                <CustomTextField
                                    select
                                    label="Status"
                                    name="status"
                                    value={status}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => { setStatus(e.target.value); setPageNo(1); }}
                                    fullWidth
                                    margin="normal"
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="success">Success</MenuItem>
                                    <MenuItem value="failure">Failure</MenuItem>
                                </CustomTextField>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <CustomTextField
                                    select
                                    label={
                                        <span>
                                            Clubs
                                        </span>
                                    }
                                    name="paymentevent"
                                    value={club}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => { setClub(e.target.value); setPageNo(1); }}
                                    fullWidth
                                    margin="normal"
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {tenantList.map((option: any) => (
                                        <MenuItem key={option.clubName} value={option.clubName}>
                                            {option.clubName}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>
                        </Grid>
                        <DataTable rows={rows} columns={columns} checkbox={false} tableId="table85"
                            totalPages={totalCount}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange} />
                    </Box>
                </BlankCard>
            </PageContainer>
        </>
    );
};

export default DistrictPaymentLogsAdmin;
