import React, { useEffect } from "react";
import fees from "src/assets/images/athlete-id-card/reg-fees.png";
import idCard from "src/assets/images/athlete-id-card/id.png";
import mail from "src/assets/images/athlete-id-card/mailtemp5.png";
import uniqueId3 from "src/assets/images/athlete-id-card/UniqueId3.png";
import competition from "src/assets/images/configurations/comp2.png"
import { Avatar, Box, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import BlankCard from "src/components/shared/BlankCard";
import APP_ROUTES from "src/routes/routePaths";
import TableHead from "src/components/table-head";
import { useSelector } from "react-redux";
import { AppState, useDispatch } from "src/store/Store";
import { setAdminType } from "src/store/reducers/TenentSlice";

const cardData = [
    {
        title: "Athlete Id Card Template",
        link: `${APP_ROUTES.DISTRICT_CLUB_ADMIN_CONFIGURATIONS}/id-card/template`,
        image: idCard,
    }
];


const DistrictClubAdminConfigurations = () => {
    const adminType = useSelector((state: AppState) => state.tenent.formData.adminType);
    const { role } = useSelector((state: AppState) => state.authToken);
    console.log("reolllllll", role);

    const dispatch = useDispatch();
    useEffect(() => {
        if (role === "district_admin") {
            dispatch(setAdminType("district"));
        };
        if (role === "club_admin") {
            dispatch(setAdminType("club"));
        };
    }, [dispatch, role]);

    const cardsData: any = cardData;
    const configurationsTitle = "Club Admin Configurations";

    return (
        <>
            <TableHead title={configurationsTitle} />
            <Grid container spacing={3} mt={1} sx={{ width: "100%" }}>
                {cardsData?.map((card: any, index: any) => (
                    <Grid key={index} item xs={12} sm={3} md={3}>
                        <BlankCard>
                            <CardContent sx={{ cursor: "pointer" }}>
                                <Link style={{ color: "#000" }} to={card.link}>
                                    <Stack direction={"column"} gap={2} alignItems="center">
                                        <Avatar alt={card.title} src={card.image} sx={{ width: "80px", height: "80px", boxShadow: "-1px 1px 8px #5d87ff9c" }} />
                                        <Box textAlign={"center"}>
                                            <Typography variant="subtitle2">{card.title}</Typography>
                                        </Box>
                                    </Stack>
                                </Link>
                            </CardContent>
                            <Divider />
                        </BlankCard>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default DistrictClubAdminConfigurations;
