import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { notifyMessage } from 'src/utils/toastNotify';
import profile from 'src/assets/images/profile/Male_Avatar.jpg';
import axiosServices from 'src/utils/axios';
import { Box, Typography, Button } from '@mui/material';

interface IDCardGeneratorProps {
    athleteId: any;
}

const DistrictAthleteIDCardGenerator: React.FC<IDCardGeneratorProps> = ({ athleteId }) => {
    const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>('horizontal');
    const [signatureImage, setSignatureImage] = useState<string | null>(null);
    const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
    const [stampImage, setStampImage] = useState<string | null>(null);
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [shooterDetails, setShooterDetails] = useState<any>({});
    const [isCollect, setIsCollect] = useState(false);

    const frontRef = useRef<HTMLDivElement>(null);
    const backRef = useRef<HTMLDivElement>(null);

    const convertToBase64 = (url: string): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            if (!url) {
                resolve(null);

                return;
            }

            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result as string);
                    };
                    reader.onerror = () => {
                        reject(new Error('Failed to convert image to Base64'));
                    };
                    reader.readAsDataURL(blob);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (athleteId) {
                try {
                    const response = await axiosServices.get(`/district/athlete/athlete-identitycard-details/${athleteId}`);
                    const data = response.data.data;
                    const signatureBase64 = await convertToBase64(data.identity_card_signature);
                    const backgroundBase64 = await convertToBase64(data.identity_card_bg_image);
                    const stampBase64 = await convertToBase64(data.identity_card_stamp);
                    const profileBase64 = await convertToBase64(data.profile_photo);
                    const createdAt = new Date(data.created_at);
                    createdAt.setDate(createdAt.getDate() + 365);
                    const updatedValidityDate = createdAt.toISOString().split('T')[0];

                    if (!signatureBase64) {
                        notifyMessage.error("Cannot download ID card as Authorized Signature is not uploaded!");
                    }

                    setSignatureImage(signatureBase64 || null);
                    setBackgroundImage(backgroundBase64 || null);
                    setStampImage(stampBase64 || null);
                    setProfileImage(profileBase64 || null);

                    setOrientation(data.identity_card_format || 'horizontal');
                    setIsCollect(data.is_collect == 1 ? true : false);
                    setShooterDetails({
                        membership_id: data.membership_id || '',
                        firstName: data.first_name || "",
                        lastName: data.last_name || "",
                        blood_group: data.blood_group || "",
                        phone: data.phone || "",
                        dOB: data.dOB || "",
                        clubName: data.club_name ? data.club_name.replace(/_/g, ' ').toUpperCase() : "",
                        membershipType: data.membership_type || "",
                        validityDate: data.membership_type === "Annual" ? updatedValidityDate : "Lifetime",
                    });
                } catch (error: any) {
                    console.error('Error fetching data:', error);
                    if (error?.response?.data?.message) {
                        notifyMessage.error(error?.response?.data?.message);
                    } else {
                        notifyMessage.error('Athlete approval status is pending!');
                    }
                }
            }
        };

        fetchData();
    }, []);

    const generatePDF = (isVertical: any) => {
        const front = frontRef.current;
        const back = backRef.current;

        if (front && back) {
            Promise.all([
                html2canvas(front, { scale: 5 }).then(canvas => canvas.toDataURL('image/png')),
                html2canvas(back, { scale: 5 }).then(canvas => canvas.toDataURL('image/png')),
            ]).then(([frontImage, backImage]) => {

                const pdf = new jsPDF('portrait', 'mm', 'a4');
                const pageWidth = pdf.internal.pageSize.getWidth() / 1.3;
                const pageHeight = pdf.internal.pageSize.getHeight() / 1.3;

                const padding = 20;

                let cardWidth, cardHeight;
                if (isVertical == "vertical") {
                    cardWidth = (pageHeight / 2) - 2 * padding - 17;
                    cardHeight = (pageWidth - 2 * padding) - 34;

                    pdf.addImage(frontImage, 'PNG', padding + 60, padding + 35, cardWidth, cardHeight);
                    pdf.addImage(backImage, 'PNG', padding + 60, cardHeight + 2 * padding + 35, cardWidth, cardHeight);
                } else {
                    cardWidth = pageWidth - 2 * padding - 20;
                    cardHeight = (pageHeight / 2) - 2 * padding - 10;

                    pdf.addImage(frontImage, 'PNG', padding + 35, padding + 40, cardWidth, cardHeight);
                    pdf.addImage(backImage, 'PNG', padding + 35, cardHeight + 2 * padding + 40, cardWidth, cardHeight);
                }

                const fileName = `shooter_id_card.pdf`;
                pdf.save(fileName);
            }).catch(error => {
                console.error('Error generating PDF:', error);
                notifyMessage.error('Error generating PDF.');
            });
        } else {
            console.error('Error: One or both refs are null.');
        }
    };

    const cardStyle: React.CSSProperties = orientation === 'vertical' ? {
        width: '250px',
        height: '350px',
        borderRadius: '5px',
    } : {
        width: '350px',
        height: '250px',
        borderRadius: '5px',
    };

    const athleteImgStyle: React.CSSProperties = orientation === 'vertical' ? {
        position: 'absolute',
        top: '55px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100px',
        height: '120px',
        borderRadius: '5px',
    } : {
        position: 'absolute',
        left: '20px',
        top: '60px',
        width: '100px',
        height: '120px',
        borderRadius: '5px',
    };

    const signatureStyle: React.CSSProperties = orientation === 'vertical' ? {
        position: 'absolute',
        bottom: '25px',
        right: '10px',
        width: '95px',
        height: '40px',
        borderRadius: '5px',
    } : {
        position: 'absolute',
        right: '10px',
        bottom: '25px',
        width: '95px',
        height: '40px',
        borderRadius: '5px',
    };

    const authorizedText: React.CSSProperties = orientation === 'vertical' ? {
        position: 'absolute',
        bottom: '0px',
        right: '10px',
        width: '95px',
        height: '40px',
        borderRadius: '5px',
        textAlign: 'center',
        lineHeight: '40px',
        fontSize: '9px',
    } : {
        position: 'absolute',
        right: '10px',
        bottom: '0px',
        width: '95px',
        height: '40px',
        borderRadius: '5px',
        textAlign: 'center',
        lineHeight: '40px',
        fontSize: '9px',
    };


    const detailsStyle: React.CSSProperties = orientation === 'vertical' ? {
        position: 'absolute',
        top: '180px',
        left: '50px',
        color: 'black',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        padding: '5px',
        textAlign: 'left',
        fontSize: '11px',
    } : {
        position: 'absolute',
        right: '40px',
        top: '60px',
        color: 'black',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        padding: '5px',
        textAlign: 'left',
        fontSize: '11px',
    };

    const clubNameStyle: React.CSSProperties = {
        position: 'absolute',
        top: orientation === 'vertical' ? '5px' : '10px',
        left: 'calc(50% - 5px)',
        transform: 'translate(-50%, 0)',
        width: 'calc(100% - 20px)',
        fontWeight: 'bold',
        fontSize: orientation === 'vertical' ? '13px' : '13px',
        color: 'black',
        textAlign: 'center',
        zIndex: 3,
    };

    const disclaimerStyle: React.CSSProperties = orientation === 'vertical' ? {
        position: 'absolute',
        bottom: '0px',
        left: '10px',
        color: 'red',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        paddingBottom: '5px',
        textAlign: 'left',
        fontSize: '8px',
    } : {
        position: 'absolute',
        bottom: '0px',
        left: '10px',
        color: 'red',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        paddingBottom: '5px',
        textAlign: 'left',
        fontSize: '8px',
    };

    return (
        <>
            <Box sx={{ mt: 2, mb: 4 }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {isCollect ? (
                        <>
                            Kindly download the Athlete ID card PDF from here and collect the physical ID card from the office.
                        </>
                    ) : (
                        <>
                            Kindly download the Athlete ID card PDF from here.
                        </>
                    )}
                </Typography>

            </Box>
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', gap: '20px' }}>
                    <div
                        ref={frontRef}
                        style={{
                            ...cardStyle,
                            position: 'relative',
                            border: '1px solid #000',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div style={clubNameStyle}>
                            {shooterDetails.clubName ? (
                                <>
                                    {shooterDetails.clubName} MEMBERSHIP CARD
                                </>
                            ) : (
                                <>
                                    SHOOTING CLUB MEMBERSHIP CARD
                                </>
                            )}
                        </div>

                        {backgroundImage && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundImage: `url(${backgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    opacity: 0.3,
                                    zIndex: 1,
                                }}
                            />
                        )}

                        <img
                            src={profileImage || profile}
                            alt="Athlete"
                            style={{ ...athleteImgStyle, zIndex: 2 }}
                        />

                        {signatureImage && (
                            <>
                                <img
                                    src={signatureImage}
                                    alt="Signature"
                                    style={{ ...signatureStyle, zIndex: 2 }}
                                />
                                <p style={{ ...authorizedText }}>Authorized Signature</p>
                            </>
                        )}

                        <div style={{ ...detailsStyle, zIndex: 2 }}>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr',
                                gap: '2px',
                                alignItems: 'center',
                            }}>
                                <div><b>ID:</b></div>
                                <div>{shooterDetails.membership_id}</div>

                                <div><b>Name:</b></div>
                                <div>{(shooterDetails?.firstName || '') + ' ' + (shooterDetails?.lastName || '')}</div>

                                <div><b>Phone:</b></div>
                                <div>{shooterDetails.phone}</div>

                                <div><b>{shooterDetails.membership_type === "Annual" ? "Valid-Upto" : "Validity"}:</b></div>
                                <div>{shooterDetails.validityDate}</div>

                                <div><b>Blood Grp:</b></div>
                                <div>{shooterDetails.blood_group}</div>
                            </div>
                        </div>

                    </div>

                    <div
                        ref={backRef}
                        style={{
                            ...cardStyle,
                            position: 'relative',
                            border: '1px solid #000',
                            // backgroundColor: '#f5f5f5',
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                opacity: 0.3,
                                zIndex: 1,
                            }}
                        />
                        {backgroundImage && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundImage: `url(${backgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    opacity: 0.3,
                                    zIndex: 1,
                                }}
                            />
                        )}
                        {stampImage && (
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: orientation === 'vertical' ? '45px' : '20px',
                                    right: orientation === 'vertical' ? '10px' : '10px',
                                    width: orientation === 'vertical' ? '80px' : '80px',
                                    height: orientation === 'vertical' ? '80px' : '80px',
                                    backgroundImage: `url(${stampImage})`,
                                    backgroundSize: 'cover',
                                    opacity: 0.7,
                                    zIndex: 2,
                                }}
                            />
                        )}
                        <div style={{ padding: '10px', textAlign: 'left' }}>
                            <p style={{ fontSize: '12px', marginBottom: '5px' }}>Warning: Misusing this card is a criminal offence.</p>
                            <div style={{ display: 'grid', gridTemplateColumns: '10px 1fr', gap: '2px', fontSize: '12px' }}>
                                <div>1)</div>
                                <div>This card is valid for the cardholder who is a registered shooter of the club.</div>

                                <div>2)</div>
                                <div>This card must be surrendered upon expiration of club membership.</div>

                                <div>3)</div>
                                <div>In case of loss, the issuing authority should be notified immediately.</div>
                            </div>
                        </div>
                        <div style={disclaimerStyle}>
                            Disclaimer: This is a system-generated ID card and must be used as per club policies.
                        </div>

                    </div>
                </div>

                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2, marginBottom: 2 }}
                        onClick={() => generatePDF(orientation)}
                        disabled={!shooterDetails.clubName || signatureImage === null}
                    >
                        Download ID card
                    </Button>
                </div>
            </div>
        </>
    );
};

export default DistrictAthleteIDCardGenerator;
