const APP_ROUTES = {
  HOME: "/",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_NAV: "/admin/nav",
  ATHLETE_DASHBOARD: "/athlete/dashboard",
  STATE_ADMIN_DASHBOARD: "/state/admin/dashboard",
  CLUB_ADMIN_DASHBOARD: "/club/admin/dashboard",
  SUPER_ADMIN: "/admin/create/tenant",
  CLUB_ADMIN_VIEW_PROFILE: "/club/admin/profile",
  SUPER_ADMIN_EDIT: "/edit-tenant",
  SUPER_ADMIN_ACCESS_CONTROL: "/access-control",
  SUPER_ADMIN_ADD_ACCESS_CONTROL: "/add-access-control",
  SUPER_ADMIN_EMAIL_INVITES: "/email-invites",
  MASTERS: "/admin/masters",
  TENANT: "/district-admin/tenant",
  USERS: "/user",
  ATHLETES: "/club-athletes",
  DISTRICT_ATHLETES: "/club-athletes-list",
  ATHLETE_ONLY: "/state-athlete",
  ATHLETE_REGESTER: "/state-athlete-register",
  ATHLETE_CLUB_ONLY: "/club-athlete",
  ATHLETE_CLUB_REGISTER: "/club-athlete-register",
  ATHLETE_CLUB_VIEW: "/club-athlete-view",
  ATHLETE_CLUB_EDIT: "/club-athlete-edit",

  ATHLETE_CLUB_RANGE_USAGES: "/club-range-usages",
  ATHLETE_CLUB_RANGE_REGISTER: "/club-range-usages/register",
  ATHLETE_CLUB_RANGE_SELECTION: "/club-range-usages/selection",
  ATHLETE_CLUB_EQUIPMENT_RENT: "/club-range-usages/equipment-rent",
  CLUB_AIR_PALETTE: "/club-range-usages/air-pallete",
  CLUB_AIR_TARGET: "/club-range-usages/air-target",
  CLUB_LOCKER_ROOM: "/club-range-usages/locker-room",
  CLUB_ATHLETE_ID_CARD: "/athletes/id-card/list",

  ADMIN_CLUB_RANGE_USAGES: "/clubadmin-range-usages",
  ADMIN_CLUB_RANGE_REGISTER: "/clubadmin-range-usages/register",
  ADMIN_CLUB_RANGE_SELECTION: "/clubadmin-range-usages/selection",
  ADMIN_CLUB_EQUIPMENT_RENT: "/clubadmin-range-usages/equipment-rent",
  ADMIN_CLUB_AIR_PALETTE: "/clubadmin-range-usages/air-pallete",
  ADMIN_CLUB_AIR_TARGET: "/clubadmin-range-usages/air-target",
  ADMIN_CLUB_LOCKER_ROOM: "/clubadmin-range-usages/locker-room",

  ATHLETE_CLUB_RENEWAL: "/club-renewal",
  ATHLETE_CLUB_COURSE: "/club-course",
  ATHLETE_CLUB_RESLUTS: "/club-results",
  ATHLETE_CLUB_COMPETITION: "/club-competition",

  CLUB_ADMIN_CONFIGURAIONS: "/clubadmin/configurations",
  CLUB_ADMIN_FEES_CONFIGURAIONS: "/clubadmin/fees/configuration",
  ATHLETE_ID_CARD_CONGIGURATIONS: "/athlete/id-card/configuration",

  EVENT_GROUP: "/event-groups",
  COACHING_CAMP: "/coaching-camp",
  STATE_COMPETITION: "/club-admin-competition",
  ATHLETE_ID_CARD: "/athlete/id-card",
  ATHLETE_COMPETITION: "/athlete-club-competition",
  TEAM_MANAGEMENT: "/team-management",
  DETAILS: "/details",
  REPORTS: "/reports",
  EQUIPMENT_CONTROL_SHEET: "/equipment-control-sheet",
  SCORE_ENTRY: "/score-enrty",
  PAYMENT_LOG: "/payment-log",
  PAYMENT_RESPONSE: "/payment-response",
  PAYMENT_FAILURE: "/payment-failed",
  CHAMPIONS: "/champions",
  STS_PAYMENT_REPORT: "/sts-payment-report",
  SHOOTERS_PAYMENT_REPORT: "/sthooters-payment-report",
  RECORDS: "/add_records",
  AWARDEES: "/awardees",
  RAILWAY_CONCESSION_CERTIFICATE: "/railway-concession-certificate",
  CREATE_RAILWAY_CONCESSION: "/railway-concession-certificate/create",
  VIEW_RAILWAY_CONCESSION: "/railway-concession-certificate/view",
  EDIT_RAILWAY_CONCESSION: "/railway-concession-certificate/edit",
  NATIONAL_MEDALISTS: "/national-medalists",
  DRA_CLUB_RU_REGISTER: "/dra-club-ru-register",
  SHOOTERS_HISTORY: "/shooters-history",
  RANGE_AVAILABILTY: "/range-availablity",
  SHOOTERS_CERTIFICATE: "/shooter-certificate",
  MONTHLY_PAYMENT_REPORT: "/monthly-payment-report",
  RENEWAL: "/renewal",
  ACTIVITY_LOG: "/activity-log",
  ALL_MATCH_PARTICIPATION_REPORT: "/all-match-participation-report",
  ALL_UPCOMING_MATCH_PARTICIPATION: "/competition/all-upcoming-match-participation",
  SHOOTER_ID_CARD: "/shooter-id-card",
  ISSF_DETAILS: "/issf-details",
  COACHING_CAMPS_WEAPON_CARRY_LETTER: "/camps-weapon-carry-letter",
  COMPETITOR_CARD: "/competitor-card",
  REQUEST_SHOOTER_CERTIFICATE_LIST: "/request-shooter-certificate-list",
  SAFETY_COURSE: "/safety-course",
  CREATE_ASSOCIATION: "/dra-club-ru-register/create",
  EDIT_ASSOCIATION: "/dra-club-ru-register/edit",
  VIEW_ASSOCIATION: "/dra-club-ru-register/view",

  DISTRICT_ADMIN_EMAIL_INVITES: "/district/email-invites",
  DISTRICT_ADMIN_VIEW_PROFILE: "/district/admin/profile",
  DISTRICT_CLUB_ADMIN_VIEW_PROFILE: "/district/clubAdmin/profile",
  DISTRICT_ADMIN_CONFIGURAIONS: "/districtadmin/configurations",
  DISTRICT_CLUB_ADMIN_FORM: "/district/create",
  DISTRICT_CLUB_ADMIN_EDIT: "/district/edit",
  DISTRICT_PAYMENT_LOG: "/district/payment-logs",
  DISTRICT_CLUB_ADMIN_CONFIGURATIONS: "/districtclubadmin/configurations",
  DISTRICT_ATHLETE_ID_CARD: "/district/athlete/id-card",
};

export default APP_ROUTES;
